<template>
  <div class="header-search">
    <div class="bb-container">
      <SearchBar/>
    </div>
  </div>
</template>


<script>
export default {
  name: "CatalogueSearchbar",
  components: {
    SearchBar: () => import( /* webpackPrefetch: true */ /* webpackChunkName: "SearchBar" */  './SearchBar.vue'),
  }
}
</script>